<template>
    <b-modal id="modal-startup-form" :hide-footer="true" modal-class="modal-w-md" title="Startup Profile" @hide="onCloseModal">
        <form class="form pb-5">

            <div class="d-flex align-items-center mb-1">
                <div class="image-block image-block--rounded--lg mb-4">
                    <img v-if="this.logo" :src="this.logo" alt="img">
                </div>
                <div>
                    <a @click="showLogoUpload = true" class="btn btn-primary">Upload Logo</a>
                    <p class="modal-text m-0">Image size: 200 X 200 pixels, max file size 5 mb</p>
                </div>
            </div>

            <image-upload
                v-model="showLogoUpload"
                :width="200"
                :height="200"
                :noSquare="true"
                langType="en"
                @crop-success="logoCropped"
            >
            </image-upload>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Company Name *</h5>
                <span class="form__error" v-for="(error, index) in errors.name" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="text" v-model="form.name">
                <div v-if="typeof lastUpdate['name'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['name'].date)}},
                    {{ lastUpdate['name'].source }})
                </div>
            </div>

            <div class="form__item input-checkbox">
                <input type="checkbox" id="hidden" v-model="form.hidden">
                <label for="hidden">Hidden</label>
            </div>

            <div class="form__item input-checkbox">
                <input type="checkbox" id="is_public" v-model="form.is_public">
                <label for="is_public">Public</label>
            </div>

            <div class="form__item input-checkbox">
                <input type="checkbox" id="sp_inactive" v-model="form.sp_inactive">
                <label for="sp_inactive">SP Inactive</label>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Short Description</h5>
                <textarea class="form-control--mid form-control" v-model="form.short_description">
                </textarea>
                <div v-if="typeof lastUpdate['short_description'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['short_description'].date)}},
                    {{ lastUpdate['short_description'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Description</h5>
                <textarea class="form-control--mid form-control" v-model="form.description">
                </textarea>
                <div v-if="typeof lastUpdate['description'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['description'].date)}},
                    {{ lastUpdate['description'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Year Founded</h5>
                <span class="form__error" v-for="(error, index) in errors.founded" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="number" v-model="form.founded">
                <div v-if="typeof lastUpdate['founded'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['founded'].date)}},
                    {{ lastUpdate['founded'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Categories</h5>
                <multiselect
                    v-model="categories"
                    :multiple="true"
                    placeholder="Select Categories"
                    :options="categoriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>

                <div v-if="typeof lastUpdate['categories'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['categories'].date)}},
                    {{ lastUpdate['categories'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Custom Categories</h5>
                <textarea class="form-control--mid form-control" v-model="form.custom_categories">
                </textarea>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Company URL *</h5>
                <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="text" v-model="form.website">

                <div v-if="typeof lastUpdate['website'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['website'].date)}},
                    {{ lastUpdate['website'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Employees</h5>
                <select v-model="num_employees" class="selectbox selectbox-mid w-100">
                    <option v-for="item in filtersOptions.employeeCount" :key="item.name" :value="item.value">
                        {{item.name}}
                    </option>
                </select>

                <div v-if="typeof lastUpdate['num_employees_min'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['num_employees_min'].date)}},
                    {{ lastUpdate['num_employees_min'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Revenue Range</h5>

                <select v-model="form.revenue_range" class="selectbox selectbox-mid w-100">
                    <option v-for="item in filtersOptions.revenueRange" :key="item.name" :value="item.value">
                        {{item.name}}
                    </option>
                </select>

                <div v-if="typeof lastUpdate['revenue_range'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['revenue_range'].date)}},
                    {{ lastUpdate['revenue_range'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Funding Series</h5>

                <select v-model="form.stage" class="selectbox selectbox-mid w-100">
                    <option v-for="(name, value) in constants.fundingTypes" :key="value" :value="value">
                        {{name}}
                    </option>
                </select>

                <div v-if="typeof lastUpdate['stage'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['stage'].date)}},
                    {{ lastUpdate['stage'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Total Raised</h5>
                <span class="form__error" v-for="(error, index) in errors.total_raised" :key="index">{{error}}</span>
                <input class="form-control--mid form-control" type="number" v-model="form.total_raised">
                <div v-if="typeof lastUpdate['total_raised'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['total_raised'].date)}},
                    {{ lastUpdate['total_raised'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Company Phone</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.company_phone">

                <div v-if="typeof lastUpdate['company_phone'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['company_phone'].date)}},
                    {{ lastUpdate['company_phone'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Addess 1</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.address1">

                <div v-if="typeof lastUpdate['address1'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['address1'].date)}},
                    {{ lastUpdate['address1'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Addess 2</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.address2">

                <div v-if="typeof lastUpdate['address2'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['address2'].date)}},
                    {{ lastUpdate['address2'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Country</h5>
                <multiselect
                    v-model="country"
                    placeholder="Select Country"
                    :options="countriesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>

                <div v-if="typeof lastUpdate['country_id'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['country_id'].date)}},
                    {{ lastUpdate['country_id'].source }})
                </div>
            </div>

            <div class="form__item" v-if="form.country_id == 1">
                <h5 class="heading5 heading5--secondary">State</h5>

                <multiselect
                    v-model="state"
                    placeholder="Select State"
                    :options="statesList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                >
                </multiselect>

                <div v-if="typeof lastUpdate['state_id'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['state_id'].date)}},
                    {{ lastUpdate['state_id'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Region Name</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.region_name">

                <div v-if="typeof lastUpdate['region_name'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['region_name'].date)}},
                    {{ lastUpdate['region_name'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">City</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.city">

                <div v-if="typeof lastUpdate['city'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['city'].date)}},
                    {{ lastUpdate['city'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Zip</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.zip">

                <div v-if="typeof lastUpdate['zip'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['zip'].date)}},
                    {{ lastUpdate['zip'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Facebook</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.facebook_url">

                <div v-if="typeof lastUpdate['facebook_url'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['facebook_url'].date)}},
                    {{ lastUpdate['facebook_url'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Twitter</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.twitter_url">

                <div v-if="typeof lastUpdate['twitter_url'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['twitter_url'].date)}},
                    {{ lastUpdate['twitter_url'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Linkedin</h5>
                <input class="form-control--mid form-control" type="text" v-model="form.linkedin_url">

                <div v-if="typeof lastUpdate['linkedin_url'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['linkedin_url'].date)}},
                    {{ lastUpdate['linkedin_url'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Keywords</h5>
                <textarea class="form-control--mid form-control" v-model="form.website_keywords">
                </textarea>

                <div v-if="typeof lastUpdate['website_keywords'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['website_keywords'].date)}},
                    {{ lastUpdate['website_keywords'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Clients</h5>

                <multiselect
                    v-model="clients"
                    :multiple="true"
                    placeholder="Select Clients"
                    :options="clientsList"
                    select-label=""
                    deselect-label=""
                    label="name"
                    track-by="name"
                    class="multiselect-mid pl-2"
                    @search-change="updateClientsList"
                >
                </multiselect>

                <div v-if="typeof lastUpdate['clients'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['clients'].date)}},
                    {{ lastUpdate['clients'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Features</h5>
                <textarea class="form-control--mid form-control" v-model="form.features"/>

                <div v-if="typeof lastUpdate['features'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['features'].date)}},
                    {{ lastUpdate['features'].source }})
                </div>
            </div>

            <div class="form__item">
                <h5 class="heading5 heading5--secondary">Patents</h5>

                <div>
                    <span v-if="syncPatentsLoading">Syncing...</span>
                    <a v-else @click="syncPatents">Sync Patents</a>
                </div>

                <div v-for="(patent, index) in form.patents" :key="index">
                    <input class="form-control form-control--mid mb-2" v-model="form.patents[index].num">
                    <input class="form-control form-control--mid mb-2" v-model="form.patents[index].title">
                    <input class="form-control form-control--mid mb-2" v-model="form.patents[index].assignor">
                    <input class="form-control form-control--mid mb-2" v-model="form.patents[index].assignee">
                    <hr>
                </div>

                <a @click="() => {form.patents.push({num: '', title: '', assignor: '', assignee: ''})}">+Add Patent</a>

                <div v-if="typeof lastUpdate['patents'] !== 'undefined'" style="font-size:11pt;color:grey" class="text-right">
                    (last edit: {{dateHelper.decodeDate(lastUpdate['patents'].date)}},
                    {{ lastUpdate['patents'].source }})
                </div>
            </div>

            <div class="text-center">
                <b-button variant="primary" size="lg" @click="save" :disabled="loading">Save</b-button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import startups from "@/services/startups.service"
import imageUpload from "vue-image-crop-upload"
import multiselect from "vue-multiselect"
import categories from "@/services/categories.service"
import locations from "@/services/locations.service"
import filterOptions from '../../../../frontend/src/components/search/filter-options'
import clientsService from "@/services/clients.service";

export default {
    components: {
        imageUpload,
        multiselect,
    },

    mixins: [
        filterOptions
    ],

    data() {
        return {
            id: 0,
            editFields: [],

            loading: false,
            form: {
                name: "",
                short_description: "",
                description: "",
                founded: "",
                categories: [],
                website: "",
                num_employees_min: "",
                num_employees_max: "",
                revenue_range: "",
                stage: "",
                company_phone: "",
                address1: "",
                address2: "",
                country_id: "",
                state_id: "",
                region_name: "",
                city: "",
                zip: "",
                facebook_url: "",
                twitter_url: "",
                linkedin_url: "",
                website_keywords: "",
                hidden: 0,
                is_public: 0,
                sp_inactive: 0,
                custom_categories: "",
                clients: "",
                features: "",
                patents: [],
            },

            logo: "",
            lastUpdate: {},

            errors: {},

            num_employees: null,
            country: null,
            state: null,

            showLogoUpload: false,
            logoChanged: false,

            categories: [],
            categoriesList: [],
            clients: [],
            clientsList: [],
            countriesList: [],
            statesList: [],
            syncPatentsLoading: false,
        }
    },

    watch: {
        country() {
            if (this.country) {
                this.form.country_id = this.country.id
            } else {
                this.form.country_id = 0
            }
        },

        state() {
            if (this.state) {
                this.form.state_id = this.state.id
            }
            else {
                this.form.state_id = 0
            }
        },
    },

    mounted() {
        categories.getList().then(response => {
            this.categoriesList = response.data
            this.setCategories()
        })

        locations.getCountriesList().then(response => {
            this.countriesList = response.data
            this.setCountry()
        })

        locations.getStatesList().then(response => {
            this.statesList = response.data
            this.setState()
        })
    },

    methods: {
        init() {
            this.categories = [];
            this.form.name = "";
            this.form.short_description = "";
            this.form.description = "";
            this.form.founded = "";
            this.form.website = "";
            this.form.num_employees_min = "";
            this.form.num_employees_max = "";
            this.form.revenue_range = "";
            this.form.stage = "";
            this.form.company_phone = "";
            this.form.country_id = "";
            this.form.state_id = "";
            this.form.region_name = "";
            this.form.city = "";
            this.form.zip = "";
            this.form.facebook_url = "";
            this.form.twitter_url = "";
            this.form.linkedin_url = "";
            this.form.website_keywords = "";
            this.form.hidden = 0;
            this.form.is_public = 0;
            this.form.sp_inactive = 0;
            this.form.categories = [];
            this.form.custom_categories = "";
            this.form.clients = [];
            this.form.features = "";
            this.form.patents = [];

            this.logo = "";
            this.logoChanged = false;
            this.num_employees = null;
            this.country = null;
            this.state = null;

            this.clearErrors();

            startups.getEdit(this.id, this.editFields).then(response => {
                for (let field in response.data.data) {
                    this.form[field] = response.data.data[field];
                }

                this.logo = response.data.logo;
                this.lastUpdate = response.data.last_update;
                this.setCategories();
                this.setClients();
                this.setCountry();
                this.setState();

                if (this.form.num_employees_min && this.form.num_employees_max) {
                    this.num_employees = this.filtersOptions.employeeCount.find(item =>
                        item.value.min === parseInt(this.form.num_employees_min) && item.value.max === parseInt(this.form.num_employees_max)
                    ).value
                }
            })
        },

        open(id, editFields = []) {
            this.id = id;
            this.editFields = editFields;

            this.init()
            this.$bvModal.show('modal-startup-form')
        },

        clearErrors() {
            for (let i in this.errors) {
                this.errors[i] = ""
            }
        },

        setCategories() {
            this.categories = this.categoriesList.filter(item1 => this.form.categories.some(item2 => parseInt(item1.id) === item2))
        },

        setClients() {
            this.clients = this.form.clients;
        },

        updateClientsList(query) {
            clientsService.getList(query).then(response => {
                this.clientsList = response.data;
            })
        },

        logoCropped(imageDataUrl) {
            this.logo = imageDataUrl
            this.showLogoUpload = false
            this.logoChanged = true
        },

        setCountry() {
            if (!this.country && this.form.country_id) {
                this.country = this.countriesList.find(item => item.id === this.form.country_id)
            }
        },

        setState() {
            if (!this.state && this.form.state_id) {
                this.state = this.statesList.find(item => item.id === this.form.state_id)
            }
        },

        save() {
            this.loading = true

            if (this.logoChanged) {
                this.form.newLogo = this.logo
            }

            this.form.categories = this.categories.map(item =>item.id);
            this.form.clients = this.clients.map(item =>item.id);

            if (this.num_employees) {
                this.form.num_employees_min = this.num_employees.min
                this.form.num_employees_max = this.num_employees.max
            }

            startups.save(this.id, this.form, this.editFields).then(response => {
                this.errors = response.data.errors

                if (! Object.keys(this.errors).length) {
                    this.$emit('saved')
                    this.$bvModal.hide('modal-startup-form')
                }
            }).finally(() => {
                this.loading = false
            })
        },

        syncPatents() {
            this.syncPatentsLoading = true;

            startups.syncPatents(this.id).then(response => {
                this.form.patents = response.data;
            }).finally(() => {
                this.syncPatentsLoading = false;
            })
        },

        onCloseModal() {
            this.$emit('modal-closed');
        }
    }
}
</script>

<style scoped>

</style>
