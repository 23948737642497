import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import store from '@/store'

import users from "@/router/users"
import startups from "@/router/startups"
import ecosystems from "@/router/ecosystems"
import enterprises from "@/router/enterprises"
import keywords from "@/router/keywords"
import coupons from "@/router/coupons";
import tools from "@/router/tools";
import trackers from "@/router/trackers";
import connections from "@/router/connections";
import analyst from "@/router/analyst";

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/',
        component: Home,
    },

    ...users,
    ...startups,
    ...ecosystems,
    ...enterprises,
    ...keywords,
    ...coupons,
    ...tools,
    ...trackers,
    ...connections,
    ...analyst,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.guest)) {
        next()
    } else {
        if (store.getters.isLoggedIn) {
            let notFound = false

            if (typeof to.meta !== "undefined" && typeof to.meta.access !== "undefined" && to.meta.access.indexOf(store.state.userType) < 0) {
                notFound = true
            }

            if (notFound) {
                store.commit('setPageNotFound', true)
            }
            else {
                store.commit('setPageNotFound', false)
                next()
            }

            return
        }
        next('/login')
    }
})

export default router
