import axios from '@/axios';

const RESOURCE_NAME = '/admin/startups';

export default {

    getStartups(type, data) {
        return axios.post(`${RESOURCE_NAME}/get-startups?type=${type}`, data)
    },

    getEdit(id, fields) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}&fields=${fields}`)
    },

    save(id, data, fields) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}`, {data, fields})
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-startup?id=${id}`)
    },

    syncCB(id) {
        return axios.post(`${RESOURCE_NAME}/sync-cb?id=${id}`)
    },

    syncKG(id) {
        return axios.post(`${RESOURCE_NAME}/sync-kg?id=${id}`)
    },

    syncElastic(id) {
        return axios.post(`${RESOURCE_NAME}/sync-elastic?id=${id}`)
    },

    syncPPX(id) {
        return axios.post(`${RESOURCE_NAME}/sync-ppx?id=${id}`)
    },

    crawl(id) {
        return axios.post(`${RESOURCE_NAME}/crawl?id=${id}`)
    },

    getRelations(id) {
        return axios.get(`${RESOURCE_NAME}/get-relations?id=${id}`)
    },

    bulkAction(action, ids) {
        return axios.post(`${RESOURCE_NAME}/bulk-action`, {action: action, ids: ids})
    },

    addSingleStartup(data) {
        return axios.post(`${RESOURCE_NAME}/add-single-startup`, data)
    },

    addMultipleStartups(data) {
        return axios.post(`${RESOURCE_NAME}/add-multiple-startups`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    getUsers(id) {
        return axios.get(`${RESOURCE_NAME}/get-users?id=${id}`)
    },

    getContacts(id) {
        return axios.get(`${RESOURCE_NAME}/get-contacts?id=${id}`)
    },

    getFundingRounds(id) {
        return axios.get(`${RESOURCE_NAME}/get-funding-rounds?id=${id}`)
    },

    saveFundingRounds(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-funding-rounds?id=${id}`, data)
    },

    getCrawlJsons(id) {
        return axios.get(`${RESOURCE_NAME}/get-crawl-jsons?id=${id}`)
    },

    downloadCrawlJson(id) {
        return axios.get(`${RESOURCE_NAME}/download-crawl-json?json_id=${id}`)
    },

    getActiveJobs(data) {
        return axios.post(`${RESOURCE_NAME}/get-active-jobs`, data)
    },

    checkJobStatus(id) {
        return axios.get(`${RESOURCE_NAME}/check-job-status?id=${id}`)
    },

    removeSyncJob(id) {
        return axios.get(`${RESOURCE_NAME}/remove-sync-job?id=${id}`)
    },

    translateKeywords(id) {
        return axios.get(`${RESOURCE_NAME}/translate-keywords?id=${id}`)
    },

    search(query) {
        return axios.get(`${RESOURCE_NAME}/search?query=${query}`)
    },

    syncPatents(id) {
        return axios.get(`${RESOURCE_NAME}/sync-patents?id=${id}`)
    },

    getGenAiData(id) {
        return axios.get(`${RESOURCE_NAME}/get-gen-ai-data?id=${id}`)
    },

    addGenAiJob(id) {
        return axios.post(`${RESOURCE_NAME}/add-gen-ai-job?id=${id}`)
    },

    copyClientsFromGenAI(id) {
        return axios.post(`${RESOURCE_NAME}/copy-clients-from-gen-ai?id=${id}`)
    },

    copyFeaturesFromGenAI(id) {
        return axios.post(`${RESOURCE_NAME}/copy-features-from-gen-ai?id=${id}`)
    },

    exportCSV(type, data) {
        return axios.post(`${RESOURCE_NAME}/export-csv?type=${type}`, data)
    },
}
