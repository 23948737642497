<template>
    <div>
        <div class="topbar">
            <div class="topbar__inner">
                <div class="topbar__key">
                    <header class="buttons-container">
                        <button class="button" @click="addStartup">Add startup</button>
                        <button v-if="selectedRows.length" class="button" @click="approve">Approve</button>
                        <button v-if="selectedRows.length" class="button" @click="markHidden">Mark as hidden</button>
                        <button v-if="selectedRows.length" class="button" @click="exportCSV">Export CSV</button>
                        <div class="input-checkbox pt-1">
                            <input id="filterInTrackers" class="ml-2" type="checkbox" v-model="filterInTrackers">
                            <label for="filterInTrackers">Present in Trackers</label>
                        </div>
                    </header>
                </div>
            </div>
        </div>

        <div class="main-table-wrapper">
            <div class="main-table overflow-auto" style="position: relative">
                <pulse-loader :loading="loading" :color="constants.spinnerColor"></pulse-loader>
                <Grid
                    :columns="actualColumns"
                    :data="startups"
                    :has-filters="true"
                    :delay-filter-input="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :addCellData="addStartupData"
                    :hasCheckboxColumn="true"
                    v-on:changePage="changePage"
                    v-on:changeFilters="changeFilters"
                    v-on:changeSort="changeSort"
                    v-on:action="gridAction"
                    v-on:selectedRowsChanged="changeSelectedRows"
                    v-on:selectAllResults="selectAllResults"
                >
                </Grid>

                <template v-if="totalRowsCount">
                    <div class="main-inner">
                        <p>Total number of startups: {{totalRowsCount}}</p>
                    </div>
                </template>
            </div>
        </div>

        <edit-startup
            ref="editStartup"
            v-on:saved="getList"
        />

        <b-modal id="modal-startup-funding-rounds" :hide-footer="true" modal-class="modal-w-md" title="Funding Rounds">
            <edit-funding-rounds
                :id="currentStartup"
                :from-startup="false"
                :startups-service="startupsService"
                :investors-service="investorsService"
                ref="editFundingRounds"
                @saved="() => {this.$bvModal.hide('modal-startup-funding-rounds')}"
            >
            </edit-funding-rounds>
        </b-modal>

        <users
            ref="users"
        >
        </users>

        <startup-relations
            ref="startupRelations"
            v-on:delete-startup="deleteStartup"
        >
        </startup-relations>

        <contacts
            ref="contacts"
        >
        </contacts>

        <add-startup
            ref="addStartup"
            :ecosystemId="ecosystemId"
            v-on:startup-created="getList"
        >
        </add-startup>

        <startup-gen-ai-modal
            ref="getAiModal"
        />
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import startupsService from "@/services/startups.service"
import investorsService from "../../../../frontend/src/services/investors.service";
import EditStartup from "@/components/startups/EditStartup"
import EditFundingRounds from "../../../../frontend/src/components/EditFundingRounds"
import StartupRelations from "@/components/startups/Relations"
import AddStartup from '@/components/startups/AddStartup'
import Users from '@/components/startups/Users'
import Contacts from '@/components/startups/Contacts.vue'
import StartupGenAiModal from '@/components/startups/StartupGenAIModal.vue'
import Vue from 'vue';
import PulseLoader from "vue-spinner/src/MoonLoader";
import ecosystemsService from "@/services/ecosystems.service";
import {defaultColumns} from "@/startupsIndexConstants";

export default {
    props: {
        viewType: String,
        ecosystemId: {
            type: Number,
            default: null,
        }
    },

    components: {
        EditStartup,
        EditFundingRounds,
        StartupRelations,
        Grid,
        AddStartup,
        Users,
        Contacts,
        PulseLoader,
        StartupGenAiModal,
    },

    mixins: [grid],

    data() {
        return {
            loading: false,
            startups: [],
            actualColumns: [],
            currentStartup: 0,

            addStartupData: {},
            filters: {},
            filterInTrackers: false,
        }
    },

    computed: {
        startupsService() {
            return startupsService;
        },

        investorsService() {
            return investorsService;
        }
    },

    mounted() {
        this.getActualColumns()
        this.getList()
    },

    watch: {
        filterInTrackers() {
            this.getList()
        },

        viewType() {
            this.startups = []
            this.getActualColumns()
            this.getList()
        },
    },

    methods: {
        getActualColumns() {
            this.actualColumns = defaultColumns.filter(column => !(['admin_email', 'registered_at'].indexOf(column.name) >= 0 && ['startups','ecosystem'].includes(this.viewType)))
        },

        getList() {
            let columns = this.arrayHelper.arrayColumn(this.actualColumns, 'name')
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let filters = this.arrayHelper.cloneArray(this.filters)
            let sort = this.sort

            filters['present_in_trackers'] = this.filterInTrackers

            if (this.ecosystemId) {
                filters['ecosystem_id'] = this.ecosystemId;
            }

            this.loading = true

            startupsService.getStartups(this.viewType, {
                columns,
                sort,
                currentPage,
                pageLength,
                filters
            }).then(response => {
                this.startups = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            }).finally(() => {
                this.loading = false
            })
        },

        changeFilters(filters) {
            this.filters = filters
            this.getList()
        },

        gridAction(params) {
            if (params.action === 'edit') {
                this.$refs.editStartup.open(params.rowId)
            } else if (params.action === 'relations') {
                this.$refs.startupRelations.open(params.rowId)
            } else if (params.action === 'sync-cb') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Sync in progress...'
                })

                startupsService.syncCB(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            } else if (params.action === 'sync-kg') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Sync in progress...'
                })

                startupsService.syncKG(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            } else if (params.action === 'sync-ppx') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Sync in progress...'
                })

                startupsService.syncPPX(params.rowId).then((response) => {
                    this.getList()

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            } else if (params.action === 'sync-elastic') {
                Vue.notify({
                    group: 'app',
                    type: 'success',
                    duration: 1000,
                    text: 'Sync in progress...'
                })

                startupsService.syncElastic(params.rowId).then((response) => {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data
                    })
                })
            } else if (params.action === 'edit-funding-rounds') {
                this.currentStartup = params.rowId;
                this.$bvModal.show('modal-startup-funding-rounds');

                this.$nextTick(() => {
                    this.$refs.editFundingRounds.init();
                })
            } else if (params.action === 'users') {
                this.$refs.users.open(params.rowId)
            } else if (params.action === 'contacts') {
                this.$refs.contacts.open(params.rowId)
            } else if (params.action === 'show-gen-ai-data') {
                this.$refs.getAiModal.open(params.rowId)
            } else if (params.action === 'delete' && this.viewType === 'ecosystem') {
                if (confirm('Remove startup connection?')) {
                    ecosystemsService.removeStartup(this.ecosystemId, params.rowId).then(() => {
                        this.getList()
                    })
                }
            }
        },

        deleteStartup(id) {
            if (confirm("Delete startup?")) {
                startupsService.delete(id).then(() => {
                    this.getList()
                    this.$refs.startupRelations.close()
                })
            }
        },

        approve() {
            if (confirm("Approve selected startup(s)?")) {
                startupsService.bulkAction('approve', this.selectedRows).then(response => {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data + " startup(s) approved"
                    })
                    this.getList()
                })
            }
        },

        markHidden() {
            if (confirm("Mark selected startup(s) as hidden?")) {
                startupsService.bulkAction('mark-hidden', this.selectedRows).then(response => {
                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: response.data + " startup(s) marked as hidden"
                    })
                    this.getList()
                })
            }
        },

        exportCSV() {
            let form = {
                sort: this.sort,
                columns: this.arrayHelper.arrayColumn(this.actualColumns, 'name'),
            };

            if (this.selectedAllResults) {
                form.filters = this.filters;
            } else {
                form.ids = this.selectedRows;
            }

            startupsService.exportCSV(this.viewType, form).then(response => {
                this.fileHelper.download(response.data, 'startups.csv')
            })
        },

        addStartup() {
            this.$refs.addStartup.open()
        },
    }
}
</script>

<style scoped>

</style>
