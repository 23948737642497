<template>
    <div>
        <div class="main-table-wrapper overflow-auto">
            <div class="main-table">
                <Grid
                    :columns="columns"
                    :data="items"
                    :has-filters="true"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    v-on:changePage="changePage"
                    v-on:changeSort="changeSort"
                    v-on:changeFilters="changeFilters"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>
        </div>

        <b-modal :hide-footer="true" id="rulesModal" modal-class="modal-w-xxl" title="User Search Rules">
            <div style="background-color: #043648; padding:20px; margin-bottom:20px;">
                <EquationBar
                    :query="query"
                    :read-only="true"
                />
            </div>
        </b-modal>
    </div>
</template>

<script>
import Grid from "../../../../frontend/src/components/Grid"
import grid from "../../../../frontend/src/mixins/grid"
import usersSearchesService from "@/services/users-searches.service";
import EquationBar from '../../../../frontend/src/components/search/EquationBar.vue';

export default {
    components: {
        Grid,
        EquationBar
    },

    mixins: [grid],

    data() {
        return {
            query: {rules: []},
            columns: [
                {
                    label: 'Company Type',
                    name: 'company_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'enterprise',
                            name: 'Enterprise'
                        },
                        {
                            id: 'ecosystem',
                            name: 'Ecosystem'
                        },
                        {
                            id: 'startup',
                            name: 'Startup'
                        },
                    ]
                },
                {
                    label: 'Company ID',
                    name: 'company_id',
                    filter: 'text',
                },
                {
                    label: 'Company Name',
                    name: 'company_name',
                    filter: 'text',
                },
                {
                    label: 'Account Type',
                    name: 'account_type',
                    filter: 'single-select',
                    options: [
                        {
                            id: 'free',
                            name: 'free'
                        },
                        {
                            id: 'paid',
                            name: 'paid'
                        },
                        {
                            id: 'test',
                            name: 'test'
                        },
                        {
                            id: 'pilot',
                            name: 'pilot'
                        },
                        {
                            id: 'trial',
                            name: 'trial'
                        },
                        {
                            id: 'invited',
                            name: 'invited'
                        },
                        {
                            id: 'standard',
                            name: 'standard'
                        },
                        {
                            id: 'premium',
                            name: 'premium'
                        },
                    ]
                },
                {
                    label: 'User',
                    name: 'email',
                    filter: 'text',
                },
                {
                    label: 'Results',
                    name: 'results',
                    sortable: true,
                },
                {
                    label: 'Created At',
                    name: 'created_at',
                    sortable: true,
                },
                {
                    name: 'actions',
                    label: 'Actions',
                    component: 'Link',
                }
            ],

            getListMethod: usersSearchesService.getSearches
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        gridAction(params) {
            if (params.action === 'view') {
                this.query.rules = JSON.parse(this.items.find(item => item.id === params.rowId).rules);
                this.$bvModal.show('rulesModal')
            }
        },
    }
}
</script>

<style scoped>

</style>
