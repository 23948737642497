<template>
  <div class="home">

      <div class="topbar">
          <div class="topbar__inner">
              <div class="topbar__key" action="">
                  <header class="topbar__key__header">
                      Showing
                      <select class="topbar__key__select" v-model="pageLength">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                      </select>

                      of {{stringHelper.formatNumber(totalRowsCount)}}
                  </header>

                  <button class="button button--sm mr-3" @click="addUser">Add user</button>
                  <button v-if="selectedAllResults || selectedRows.length" class="button button--sm mr-3" @click="exportCSV">Export CSV</button>
              </div>
          </div>
      </div>

      <main class="main" :class="{'main--collapsed': leftCollapsed}">
          <aside class="aside">
              <div class="aside__inner">
                  <header class="aside__header">
                      Search by

                      <a class="aside__collapse" @click="leftCollapsed = !leftCollapsed"></a>
                  </header>

                  <ul class="filters__list">
                      <li>
                          <a class="filters__list__title" v-b-toggle.filter-company_type>
                              Account Type
                          </a>

                          <b-collapse class="filters__list__content" id="filter-company_type">
                              <select class="form-control form-control--mid" v-model="filters.company_type">
                                  <option value="enterprise">Enterprise</option>
                                  <option value="ecosystem">Ecosystem</option>
                                  <option value="startup">Startup</option>
                              </select>
                          </b-collapse>
                      </li>

                      <li>
                          <a class="filters__list__title" v-b-toggle.filter-name>
                              Name or Email
                          </a>

                          <b-collapse class="filters__list__content" id="filter-name">
                              <input class="form-control form-control--mid" type="text" v-debounce:500="getList" v-model="filters.name">
                          </b-collapse>
                      </li>

                      <li v-if="filters.company_type === 'enterprise'">
                          <a class="filters__list__title" v-b-toggle.filter-license>
                              License
                          </a>

                          <b-collapse class="filters__list__content" id="filter-license">
                              <multiselect
                                  v-model="filters.license"
                                  :multiple="true"
                                  placeholder="Select License"
                                  :options="licenseOptions"
                                  select-label=""
                                  deselect-label=""
                                  label="name"
                                  track-by="name"
                                  class="multiselect-mid pl-2"
                              >
                              </multiselect>
                          </b-collapse>
                      </li>

                      <li>
                          <a class="filters__list__title" v-b-toggle.filter-account_name>
                              Account name
                          </a>

                          <b-collapse class="filters__list__content" id="filter-account_name">
                              <multiselect
                                  v-model="filters.companies"
                                  :multiple="true"
                                  placeholder="Select Company"
                                  :options="companiesList"
                                  select-label=""
                                  deselect-label=""
                                  label="name"
                                  track-by="name"
                                  class="multiselect-mid pl-2"
                                  @search-change="getCompaniesList"
                                  :loading="loadingCompaniesList"
                              >
                              </multiselect>
                          </b-collapse>
                      </li>

                      <li>
                          <a class="filters__list__title" v-b-toggle.filter-period>
                              Created At
                          </a>

                          <b-collapse class="filters__list__content" id="filter-period">
                              <date-picker v-model="period" range></date-picker>
                          </b-collapse>
                      </li>

                      <li>
                          <a class="filters__list__title" v-b-toggle.filter-inactive_period>
                              Inactive During
                          </a>

                          <b-collapse class="filters__list__content" id="filter-inactive_period">
                              <select class="form-control form-control--mid" v-model="filters.inactive_period">
                                  <option></option>
                                  <option value="30">30 days</option>
                                  <option value="60">60 days</option>
                                  <option value="90">90 days</option>
                                  <option value="365">365 days</option>
                                  <option value="never">Never</option>
                              </select>
                          </b-collapse>
                      </li>
                  </ul>
              </div>
          </aside>

          <div class="content">
              <div class="content__inner">
                  <Grid :columns="columns"
                        :static-data="true"
                        :has-checkbox-column="true"
                        :data="users"
                        :page-length="pageLength"
                        :total-rows-count="totalRowsCount"
                        :select-all-rows="true"
                        :sort="sort"
                        :currentPage="currentPage"
                        v-on:changePage="changePage"
                        v-on:changeSort="changeSort"
                        v-on:action="gridAction"
                        v-on:selectedRowsChanged="changeSelectedRows"
                        v-on:selectAllResults="selectAllResults"
                  >
                  </Grid>
              </div>
          </div>
      </main>

      <edit-user
          ref="editUser"
          v-on:saved="getList"
      >
      </edit-user>

  </div>
</template>

<script>

import Grid from "@/components/Grid"
import grid from "@/mixins/grid";
import users from "@/services/users.service";
import Vue from "vue";
import EditUser from "@/components/users/EditUser";
import accountTypes, {licenseTypes} from "../../../../frontend/src/constants/accountTypes";
import multiselect from "vue-multiselect";
import debounce from "@/mixins/debounce";
import DatePicker from "vue2-datepicker";

export default {
    mixins: [grid, debounce],

    components: {
        multiselect,
        Grid,
        EditUser,
        DatePicker,
    },

    data() {
        return {
            users: [],

            columns: [
                {
                    label: 'Name',
                    name: 'name',
                    orderable: true,
                },
                {
                    label: 'Email',
                    name: 'email',
                    orderable: true,
                },
                {
                    label: 'Last Activity',
                    name: 'last_activity',
                    orderable: true,
                },
                {
                    label: 'Lessons',
                    name: 'lessons',
                },
                {
                    label: '',
                    name: 'actions',
                    component: 'Actions',
                },
            ],

            filters: {
                company_type: accountTypes.ENTERPRISE,
                name: '',
                license: [],
                companies: [],
                created_at: [null, null],
                inactive_period: null,
            },

            period: [null, null],
            companiesList: [],
            loadingCompaniesList: false,
            leftCollapsed: false,
        }
    },

    mounted() {
        this.getList();

        for (let filterName in this.filters) {
            if (filterName !== 'name') {
                this.$watch('filters.' + filterName, () => {
                    this.getList()
                })
            }
        }
    },

    watch: {
        'filters.company_type': {
            immediate: true,
            handler() {
                this.getCompaniesList();

                if (this.filters.companies.length) {
                    this.filters.companies = [];
                } else {
                    this.getList();
                }
            }
        },

        period() {
            this.filters.created_at = [
                this.dateHelper.encodeDateForServer(this.period[0]),
                this.dateHelper.encodeDateForServer(this.period[1]),
            ]
        }
    },

    computed: {
        licenseOptions() {
            return Object.entries(licenseTypes).map(item => ({
                value: item[0],
                name: item[1],
            }))
        },

        serverFilters() {
            return {
                ...this.filters,
                license: this.filters.license.map(item => item.value),
                companies: this.filters.companies.map(item => item.id),
            }
        }
    },

    methods: {
        getList() {
            let columns = this.arrayHelper.arrayColumn(this.columns, 'name')
            let sort = this.sort
            let currentPage = this.currentPage
            let pageLength = this.pageLength

            users.getList({columns, sort, filters: this.serverFilters, currentPage, pageLength}).then(response => {
                this.users = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        getCompaniesList(search = "") {
            this.debounce(() => {
                this.loadingCompaniesList = true
                users.getCompaniesList(this.filters.company_type, search).then(response => {
                    this.companiesList = response.data
                    this.loadingCompaniesList = false
                })
            })
        },

        gridAction(params) {
            if (params.action === 'impersonate') {

                users.getAdminToken(params.rowId).then(response => {
                    let token = response.data
                    let link = this.mainDomain + '/impersonate/' + this.$store.state.token + '/' + token
                    window.open(link, '_blank')
                })
            } else if (params.action === 'send-activation-email') {
                users.sendActivationEmail(params.rowId).then((response) => {

                    let notifyText

                    if (response.data) {
                        notifyText = "Email sent"
                    }
                    else {
                        notifyText = "Error occurred while sending message"
                    }

                    Vue.notify({
                        group: 'app',
                        type: 'success',
                        duration: 5000,
                        text: notifyText
                    })
                })
            } else if (params.action === 'reset-lessons') {
                if (confirm("Reset user's lessons?")) {
                    users.resetLessons(params.rowId).then(() => {
                        this.getList();
                    })
                }
            }
        },

        addUser() {
            this.$refs.editUser.open(0)
        },

        exportCSV() {
            let result

            if (this.selectedAllResults) {
                result = users.exportAll(this.serverFilters)
            } else {
                result = users.export(this.selectedRows)
            }

            result.then(response => {
                this.fileHelper.download(response.data, 'users.csv')
            })
        },
    }
}
</script>
