<template>
    <b-modal id="modal-gen-ai-data" :hide-footer="true" modal-class="modal-w-xl" title="GenAI Data">
        <div class="mb-3" v-if="startupData">
            <div class="card card--bordered card--info">
                <h5 class="heading5">Main DB</h5>
                <p>
                    <b>Clients:</b> {{startupData.main.clients}}
                </p>
                <p>
                    <b>Features:</b> {{startupData.main.features}}
                </p>
            </div>

            <template v-if="startupData.gen_ai.exists">
                <div class="card card--bordered card--info">
                    <h5 class="heading5">GenAI DB</h5>

                        <p>
                            <b>Clients (status {{startupData.gen_ai.clients_status}}):</b> {{startupData.gen_ai.clients}}
                        </p>
                        <template v-if="startupData.gen_ai.clients">
                            <p><a class="btn btn-primary btn-copy btn-sm" @click="copyClients">Copy to Main DB</a></p>
                        </template>
                        
                        <p>
                            <b>Features (status {{startupData.gen_ai.features_status}}):</b> {{startupData.gen_ai.features}}
                        </p>
                        <template v-if="startupData.gen_ai.features">
                            <p><a class="btn btn-primary btn-copy btn-sm" @click="copyFeatures">Copy to Main DB</a></p>
                        </template>
                </div>
            </template>
            <template v-else>
                <a @click="addAIJob" class="link">Set Clients and Features Gen AI job</a>
            </template>
        </div>
    </b-modal>
</template>

<script>
import startupsService from "@/services/startups.service";

export default {
    data() {
        return {
            id: 0,
            startupData: null,
        }
    },

    methods: {
        open(id) {
            this.id = id;
            this.getData();
        },

        getData() {
            startupsService.getGenAiData(this.id).then(response => {
                this.startupData = response.data;
                this.$bvModal.show('modal-gen-ai-data');
            })
        },

        addAIJob() {
            startupsService.addGenAiJob(this.id).then(() => {
                this.getData();
            })
        },

        copyClients() {
            if (confirm('Copy clients to main DB?')) {
                startupsService.copyClientsFromGenAI(this.id).then(() => {
                    this.getData();
                })
            }
        },

        copyFeatures() {
            if (confirm('Copy features to main DB?')) {
                startupsService.copyFeaturesFromGenAI(this.id).then(() => {
                    this.getData();
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
